import ClientOnly from 'vue-client-only'
import lazyComponent from 'public/src/services/lazyComponent/lazy-hydrate' // 组件懒加载
import ImmersiveBanner from '../home-bff-components/immersive-banner/Index.vue'

/** 首页组件
 * TODO: 增加组件后需在comp-bff-map 文件中检查是否存在对应的组件映射
 */
const componentsMap = {
  // 店铺横向卡片列表
  'StaticImage': () => import(/* webpackChunkName: "bff-static-image" */'../home-bff-components/static-image/StaticImageFactory'),  
  'StaticImageCardController': () => import(/* webpackChunkName: "bff-static-image" */'../home-bff-components/static-image-card-controller/index.vue'),  

  'SideslipImage': () => import(/* webpackChunkName: "bff-sideslip-image" */'../home-bff-components/sideslip-image-static/index.vue'),
  // 灵活布局
  'MultiLine': () => import(/* webpackChunkName: "bff-multi-line" */'../home-bff-components/multi-line/Index.vue'),

  // 沉浸式banner
  // 'ImmersiveBanner': () => import(/* webpackChunkName: "bff-immersive-banner" */'../home-bff-components/immersive-banner/Index.vue'),
  ImmersiveBanner,

  // 免邮
  'FreeShippingInfo': () => import(/* webpackChunkName: "bff-free-shipping" */'../home-bff-components/free-shipping/index.vue'),

  // 横向滑动商品组件
  'HorizontalItems': () => import(/* webpackChunkName: "bff-horizontal-items" */'../home-bff-components/horizontal-items/Index.vue'),
  
  // 三档券
  'CouponPackage': () => import(/* webpackChunkName: "bff-coupon-package" */'../home-bff-components/coupon-package/Index.vue'),

  // 闪购组件
  'FlashSaleBanner': () => import(/* webpackChunkName: "bff-flash-sale" */'../home-bff-components/flash-sale-banner/Index.vue'),
  
  // 闪购
  'FlashSale': () => import(/* webpackChunkName: "bff-flash-sale" */'../home-bff-components/flash-sale-banner/Index.vue'),
  
  // 左图闪购
  'LeftFlashSale': () => import(/* webpackChunkName: "bff-flash-sale" */'../home-bff-components/flash-sale-banner/Index.vue'),

  // 品类推荐（非占位） 非All 频道使用
  'CategoryRecommendDynamic': () => import(/* webpackChunkName: "bff-category-recommend-dynamic" */'../home-bff-components/category-recommend-dynamic/index.vue'),

  // 排行榜入口
  'RankingEntrance': () => import(/* webpackChunkName: "bff-ranking-entrance" */'../home-bff-components/ranking-entrance/index.vue'),
  // 新客组件
  'NewUserHome': () => import(/* webpackChunkName: "bff-new-user-home" */'../home-bff-components/new-user-home/Index.vue'),
  // TODO： 后续组件迁移目录到bff 
  // SBC 品类热度推荐 占位&产品化 组件 （All 频道使用）
  'CategoryRecommendPlaceholder': () => import(/* webpackChunkName: "bff-category-recommend-placeholder" */'../components/category-recommend-placeholder/CategoryRecommendPlaceholder.vue'),
  // 首页凑单券
  'PurchaseCouponBanner': () => import(/* webpackChunkName: "ccc-purchase-coupon-banner" */'../components/purchase-coupon-banner/index.vue'),
  // 不贵组件
  'SuperDeals': () => import(/* webpackChunkName: "bff-super-deals" */'../home-bff-components/super-deals/Index.vue'),
  // 二分之一组件
  'HalfComponentContainer': () => import(/* webpackChunkName: "bff-ccc-half-comp" */'../home-bff-components/half/Index.vue'),
  // 新客权益融合组件
  'PolicyNewUserContainer': () => import(/* webpackChunkName: "new-user-profits" */'../home-bff-components/new-user-profits/MainContainer.vue'),
  // 游戏专区
  'GameZone': () => import(/* webpackChunkName: "game-zone" */'../home-bff-components/game-zone/GameZone.vue'),
}

const components = Object.entries(componentsMap).reduce((prev, cur) => {
  if (['HorizontalAnchor', 'HorizontalAnchorImage', 'Welfare', 'ImmersiveBanner'].includes(cur[0])) {
    prev[`${cur[0]}`] = cur[1]
  } else {
    prev[`${cur[0]}`] = lazyComponent({
      // propWhole: true,
      componentFactory: cur[1],
      customProps: {
        index: 'number',
        propData: 'object',
        propHooks: 'object',
        content: 'array',
        context: 'object',
        sceneData: 'object',
        cateLinks: 'object',
        language: 'object',
        brand: 'string',
        isLast: 'boolean',
        isLoad: 'boolean',
        lazyForceShow: 'boolean'
      }
    })
  }
  return prev
}, {})

components['ClientOnly'] = ClientOnly

// 高频使用的组件
const PREFETCH_COMPONENTS = {
  'StaticImage': 'bff-static-image', 
  'StaticImageCardController': 'bff-static-image', 
  'SideslipImage': 'bff-sideslip-image', 
  'CategoryRecommendDynamic': 'bff-category-recommend-dynamic', 
  'CategoryRecommendPlaceholder': 'bff-category-recommend-placeholder', 
  'MultiLine': 'bff-multi-line',
  'CouponPackage': 'bff-coupon-package',
  'PurchaseCouponBanner': 'ccc-purchase-coupon-banner',
  'FreeShippingInfo': 'bff-free-shipping'
}
export const generateHighFrequencyComponents = () => {
  return Object.keys(PREFETCH_COMPONENTS).map(key => ({
    chunkName: PREFETCH_COMPONENTS[key],
    resolve: componentsMap[key]
  })).filter(n => !!n.chunkName)
}

export default components
