<script lang="jsx">
import EmptyNode from './emptyNode'
import ProductItem from 'public/src/pages/components/ccc/base/ProductItemSimpleCccBff.vue'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg'
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin'
import useProductItemMixin from 'public/src/pages/components/ccc/components/mixins/use-product-item'
import { getAnalysisData } from './utils'
import { BANNER_TYPE } from './constants.js'

export default {
  name: 'ImmersiveNewProduct',
  mixins: [mixins, useProductItemMixin],
  props: {
    context: {
      type: Object,
      default: () => {}
    },
    itemConfig: {
      type: Object,
      default: () => {}
    },
    item: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    },
    bannerType: {
      type: Number,
      default: 0
    },
    cssRight: {
      type: Boolean,
      default: false,
    },
    isR56: {
      type: Boolean,
      default: false,
    },
    clickProduct: {
      type: Function,
      default: () => {}
    },
    analysisProductItem: {
      type: Function,
      default: () => {}
    },
    bannerIns: {
      type: Object,
      default: () => {}
    },
    isH1AutoACarousel: {
      type: Boolean,
      default: false,
    },
    currentProductIndex: {
      type: Number,
      default: 0
    }
  },
  render() {
    const { 
      item, 
      index, 
      bannerType,
      cssRight, 
      itemConfig, 
      itemLanguage, 
      constantData, 
      context, 
      clickProduct, 
      analysisProductItem, 
      bannerIns, 
      isH1AutoACarousel, 
      currentProductIndex,
    } = this
    
    const priceConfig = {
      showCamelPrice: true,
      camelPriceResizeFontsize: true,
      hideAllBottomPrices: !Number(item?.showPrice),
      style: {
        camelPriceStyle: {
          before: {
            fontSize: '11',
          },
          after: {
            fontSize: '8',
          }
        }
      }
    }
    
    // bannerType = 3,自动聚合样式A（外露一个商品）; bannerType = 4,自动聚合样式B(外露两个商品)
    // bannerType = 5,自动聚合样式C(外露两个商品,商品倾斜角度不同);

    const deviceRatio = 37.5
    const bannerTypeBBackgroundImg = 'https://img.ltwebstatic.com/images3_ccc/2024/09/02/3f/1725276696b26b742ce4b833af3ce21a67cbcfba4c.webp'

    const getProductContainerStyle = (autoGoodsConfig) => {
      let verticalStyle = bannerType === BANNER_TYPE.AutoGoodsA ? {
        top: `${(+autoGoodsConfig?.goodsVerticalRatio?.split(':')[0] + 3) / deviceRatio}rem`,
      } : {
        bottom: `${+autoGoodsConfig?.goodsYOffset / deviceRatio}rem`,
      }

      return {
        position: 'absolute',
        ...verticalStyle,
        ...cssRight ? {
          left: `${(bannerType === BANNER_TYPE.AutoGoodsA ? (+autoGoodsConfig?.goodsXOffset - 3) : +autoGoodsConfig?.goodsXOffset) / deviceRatio}rem`,
        } : {
          right: `${(bannerType === BANNER_TYPE.AutoGoodsA ? (+autoGoodsConfig?.goodsXOffset - 3) : +autoGoodsConfig?.goodsXOffset) / deviceRatio}rem`,
        },
      }
    }

    const getProductItemStyle = (autoGoodsConfig, productIndex = 0) => {
      return {
        width: `${+autoGoodsConfig?.goodsWidth / deviceRatio}rem`,
        height: `${+autoGoodsConfig?.goodsHeight / deviceRatio}rem`,
        ...bannerType === BANNER_TYPE.AutoGoodsA ? {
          transform: `rotate(${cssRight ? +autoGoodsConfig?.goodsRotationAngle : -(+autoGoodsConfig?.goodsRotationAngle)}deg) translateZ(0)`,
          border: '3px solid #fff',
          borderBottomWidth: '6px',
          '-webkit-backface-visibility': 'hidden',
          boxSizing: 'content-box',
        } : {
          // 两个商品的时，间距为 2px
          ...productIndex === 0 && !cssRight && {
            marginRight: '.0533rem'
          },
          ...productIndex === 0 && cssRight && {
            marginLeft: '.0533rem'
          }
        }
      }
    }
    // 自动聚合样式C商卡样式
    const getAutoGoodsCStyle = (autoGoodsConfigs, productIndex) => {
      let positionStyle = {
        top: `${(+autoGoodsConfigs[productIndex]?.goodsVerticalRatio?.split(':')[0] - 2) / deviceRatio}rem`,
        ...cssRight ? {
          left: `${(+autoGoodsConfigs[productIndex]?.goodsXOffset - 3) / deviceRatio}rem`,
        } : {
          right: `${(+autoGoodsConfigs[productIndex]?.goodsXOffset - 3) / deviceRatio}rem`,
        },
      }
      return {
        ...positionStyle,
        position: 'absolute',
        width: `${+autoGoodsConfigs[productIndex]?.goodsWidth / deviceRatio}rem`,
        height: `${+autoGoodsConfigs[productIndex]?.goodsHeight / deviceRatio}rem`,
        transform: `rotate(${cssRight ? +autoGoodsConfigs[productIndex]?.goodsRotationAngle : -(+autoGoodsConfigs[productIndex]?.goodsRotationAngle)}deg) translateZ(0)`,
        border: '3px solid #fff',
        borderBottomWidth: '6px',
        '-webkit-backface-visibility': 'hidden',
        boxSizing: 'content-box',
        zIndex: productIndex === 0 ? 2 : 0,
      }
    }

    // 样式C阴影
    const getAutoGoodsCShadowStyle = (autoGoodsConfig) => {
      let positionStyle = {
        top: `${(+autoGoodsConfig?.goodsVerticalRatio?.split(':')[0] - 5) / deviceRatio}rem`,
        ...cssRight ? {
          left: `${(+autoGoodsConfig?.goodsXOffset - 3 - 1) / deviceRatio}rem`,
        } : {
          right: `${(+autoGoodsConfig?.goodsXOffset - 3 - 1) / deviceRatio}rem`,
        },
      }
      return {
        ...positionStyle,
        position: 'absolute',
        zIndex: 1,
        transform: `rotate(${cssRight ? +autoGoodsConfig?.goodsRotationAngle : -(+autoGoodsConfig?.goodsRotationAngle)}deg) translateZ(0)`,
      }
    }
  
    const backgroundImageStyle = {
      position: 'absolute',
      width: '3.5733rem',
      height: '1.8667rem', 
      bottom: '-0.1067rem',
      right: '-0.1067rem',
      ...cssRight && { transform: 'scaleX(-1)' } // ar 站点需要水平翻转
    }

    return (
      <div
        class="immersiveBanner-new-products"
        style={{ 
          'direction': cssRight ? 'rtl' : 'ltr', 
          ...(bannerType !== BANNER_TYPE.AutoGoodsC && getProductContainerStyle(item.autoGoodsConfigs[0] || {})) 
        }}
      >
        <EmptyNode>
          {
            bannerType === BANNER_TYPE.AutoGoodsA && item.productList?.length >= 1 && !isH1AutoACarousel && (
              <div
                key={`${index}_${bannerType}`}
                data-key={`${index}_${bannerType}`}
                class={{ 'immersiveBanner-new-products__item': true }}
                style={getProductItemStyle(item.autoGoodsConfigs[0] || {})}
              >
                <ProductItem
                  ref="goodsItem"
                  item={ item.productList[0] }
                  index={ index }
                  config={ { ...itemConfig, ...priceConfig } }
                  goods-item-info={this.goodsItemInfo}
                  language={ context?.language || {} }
                  constant-data={ constantData }
                  column={ `4.1l` }
                  context={ context }
                  css-right={ cssRight }
                  crop-rate={ '14-17' }
                  lcp={ index === 0 }
                  comp-src={'immersiveBanner'}
                  comp-img-design-width={192}
                  class={{
                    'c-immersiveBanner__price-color-black': item.homeDiscountNew === 'noshow' || item.homeDiscountNew === 'compliance',
                    'not-fsp-element': index > 0,
                    'c-immersiveBanner__price-wrapper': true,
                    'c-immersiveBanner__product-item-A': true,
                  }}
                  style={{ '--crop-img-fit': 'cover' }}
                  onClickItem={clickProduct}
                  nativeOn={{
                    click: e => e.stopPropagation()
                  }}
                  v-tap={getAnalysisData.call(bannerIns, '2-22-4', { item: analysisProductItem(item), index, useBffApi: true, isJumpToList: true, product: item.productList[0] })}
                >
                </ProductItem>
              </div>
              
            )
          }
          {
            bannerType === BANNER_TYPE.AutoGoodsA && item.productList?.length >= 1 && isH1AutoACarousel && (
              <EmptyNode>
                <div
                  class={{ 
                    'immersiveBanner-new-products__item': true,
                  }}
                  style={{
                    ...getProductItemStyle(item.autoGoodsConfigs[0] || {}),
                    position: 'relative',
                    background: '#fff',
                  }}
                >
                  {
                    item.productList.map((listItem, listIndex) => (
                      <ProductItem
                        key={`${index}_${bannerType}_${listIndex}`}
                        data-key={`${index}_${bannerType}_${listIndex}`}
                        ref="goodsItem"
                        item={ listItem }
                        index={ index }
                        config={ { ...itemConfig, ...priceConfig } }
                        goods-item-info={this.goodsItemInfo}
                        language={ itemLanguage }
                        constant-data={ constantData }
                        column={ `4.1l` }
                        context={ context }
                        css-right={ cssRight }
                        crop-rate={ '14-17' }
                        lcp={ index === 0 }
                        comp-src={'immersiveBanner'}
                        comp-img-design-width={192}
                        class={{
                          'c-immersiveBanner__price-color-black': item.homeDiscountNew === 'noshow' || item.homeDiscountNew === 'compliance',
                          'not-fsp-element': index > 0,
                          'c-immersiveBanner__price-wrapper': true,
                          'c-immersiveBanner__product-item-A': true,
                          'switch-item-A': item.productList.length === 2 && true,
                          'switch-item-A-actived': item.productList.length === 2 && currentProductIndex === listIndex,
                        }}
                        style={{ '--crop-img-fit': 'cover', position: 'absolute' }}
                        onClickItem={clickProduct}
                        nativeOn={{
                          click: e => e.stopPropagation()
                        }}
                        v-tap={getAnalysisData.call(bannerIns, '2-22-4', { item: analysisProductItem(item), index, useBffApi: true, isJumpToList: true, product: listItem })}
                      >
                      </ProductItem>
                    ))
                  }
                 
                </div>
              </EmptyNode>
            )
          }
          {
            bannerType === BANNER_TYPE.AutoGoodsB && item.productList?.length >= 2 && (
              <EmptyNode>
                <BaseImg
                  first-screen={ true }
                  is-support-webp={ true }
                  placeholder={
                    {
                      width: 134,
                      height: 70,
                    }
                  }
                  img-src={ bannerTypeBBackgroundImg }
                  fit={ 'fill' }
                  style={ backgroundImageStyle} 
                />
                {
                  item.productList.map((listItem, listIndex) => (
                    <div
                      key={`${index}_${bannerType}_${listIndex}`}
                      data-key={`${index}_${bannerType}_${listIndex}`}
                      class={{ 'immersiveBanner-new-products__item': true }}
                      style={getProductItemStyle(item.autoGoodsConfigs[0] || {}, listIndex)}
                    >
                      <ProductItem
                        ref="goodsItem"
                        item={ listItem }
                        index={ index }
                        config={ { ...itemConfig, ...priceConfig } }
                        goods-item-info={this.goodsItemInfo}
                        language={ context?.language || {} }
                        constant-data={ constantData }
                        column={ `4.1l` }
                        context={ context }
                        css-right={ cssRight }
                        crop-rate={ '1-1' }
                        lcp={ index === 0 }
                        comp-src={'immersiveBanner'}
                        comp-img-design-width={192}
                        class={{
                          'c-immersiveBanner__price-color-black': item.homeDiscountNew === 'noshow' || item.homeDiscountNew === 'compliance',
                          'not-fsp-element': index > 0,
                          'c-immersiveBanner__price-wrapper': true,
                          'c-immersiveBanner__product-item-B': true,
                        }}
                        style={ { '--crop-img-fit': 'cover' }}
                        onClickItem={clickProduct}
                        nativeOn={{
                          click: e => e.stopPropagation()
                        }}
                        v-tap={getAnalysisData.call(bannerIns, '2-22-4', { item: analysisProductItem(item), index, useBffApi: true, isJumpToList: true, product: listItem })}
                      >
                      </ProductItem>
                    </div>
                  ))
                }
              </EmptyNode>
            )
          }
          {
            bannerType === BANNER_TYPE.AutoGoodsC && item.productList?.length >= 2 && (
              <EmptyNode>
                <div
                  class={{ 
                    'immersiveBanner-new-products__item-shadow': true,
                  }}
                  style={getAutoGoodsCShadowStyle(item.autoGoodsConfigs[0] || {})}
                ></div>
                {
                  item.productList.map((listItem, listIndex) => (
                    <div
                      key={`${index}_${bannerType}_${listIndex}`}
                      data-key={`${index}_${bannerType}_${listIndex}`}
                      class={{ 
                        'immersiveBanner-new-products__item': true,
                      }}
                      style={{
                        ...getAutoGoodsCStyle(item.autoGoodsConfigs || [], listIndex),
                      }}
                    >
                      <ProductItem
                        ref="goodsItem"
                        item={ listItem }
                        index={ index }
                        config={ { ...itemConfig, ...priceConfig } }
                        goods-item-info={this.goodsItemInfo}
                        language={ itemLanguage }
                        constant-data={ constantData }
                        column={ `4.1l` }
                        context={ context }
                        css-right={ cssRight }
                        crop-rate={ '14-17' }
                        lcp={ index === 0 }
                        comp-src={'immersiveBanner'}
                        comp-img-design-width={192}
                        class={{
                          'c-immersiveBanner__price-color-black': item.homeDiscountNew === 'noshow' || item.homeDiscountNew === 'compliance',
                          'not-fsp-element': index > 0,
                          'c-immersiveBanner__price-wrapper': true,
                          'c-immersiveBanner__product-item-A': true,
                        }}
                        style={{ '--crop-img-fit': 'cover' }}
                        onClickItem={clickProduct}
                        nativeOn={{
                          click: e => e.stopPropagation()
                        }}
                        v-tap={getAnalysisData.call(bannerIns, '2-22-4', { item: analysisProductItem(item), index, useBffApi: true, isJumpToList: true, product: listItem })}
                      >
                      </ProductItem>
                    </div>
                  ))
                }
              </EmptyNode>
            )
          }
        </EmptyNode>
      </div>
    )
  }
}
</script>

<style lang="less">
.immersiveBanner-new-products {
    display: flex;
    background-color: transparent;
    font-family: Helvetica, Arial, sans-serif;
    z-index: @zindex-select;
    position: relative;

    &__item-shadow {
      width: .16rem;
      height: 2.1867rem;
      background: rgba(0, 0, 0, 0.12);
      filter: blur(.0533rem);
    }
    
    &__item {
      .switch-item-A {
        z-index: @zindex-hack;
        opacity: 0;
        transition: opacity .1s ease-out;
      }
      .switch-item-A-actived {
        opacity: 1;
        z-index: @zindex-out;
        transition: opacity .1s ease-in;
      }
      .c-immersiveBanner__product-item-A {
        .product-card__top-wrapper {
          border-radius: 0 !important;
        }
      }
      .c-immersiveBanner__product-item-A .crop-image-container .product-card__main-img {
        border-radius: 0 !important;
      }
      .c-immersiveBanner__price-wrapper .product-card__bottom-wrapper {
        padding: 0;
      }
      
      .c-immersiveBanner__price-color-black .bff-price-container .prices-info__sale-price {
        color: #222;
      }
      .c-immersiveBanner__product-item-A .bff-price-container .bff-price-container__top {
        max-width: 1.86rem;
      }
      .c-immersiveBanner__product-item-B .bff-price-container .bff-price-container__top {
        max-width: 1.5rem;
      }
      .bff-price-container .bff-price-container__top {
        height: auto !important;
        display: inline-block;
      }
      .bff-price-container {
        position: absolute;
        bottom: 8/75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0;
       
        width: 100%;
        .c-immersiveBanner__price-red {
          color: #FA6338;
        }

        .prices-info__sale-price {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          border-radius: .5333rem;
          padding: 0 .1067rem;
          text-align: center;
          font-family: Helvetica, Arial, sans-serif;
          background-color: rgba(255, 255, 255, 0.8);
          height: .3467rem;
          line-height: .3467rem;
        }
    }
  }
}
</style>
