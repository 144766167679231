<template>
  <SimpleProductItem
    class="product-bff"
    :class="{ 'product-bff__view_more': showViewMore, 'product-bff__border_out': item.homeBorder?.image?.src || item.maskLayer?.image, }"
    :item="item"
    :goods-item-info="goodsItemInfo"
    :language="language"
    :index="index"
    :lcp="lcp"
    :customConfig="config"
    :column="column"
    :constant-data="constantData"
    :comp-src="compSrc"
    :cropRate="cropRate"
    :comp-img-design-width="compImgDesignWidth"
    @clickItem="clickItem"
    @openQuickAdd="openQuickAdd"
  >
    <!-- 商品蒙层 -->
    <div
      v-if="item.maskLayer?.image"
      slot="imgTopLeft"
      class="product-bff__border"
      :style="borderStyle(webpCheck(item.maskLayer?.image))"
    ></div>
    <!-- 商品边框 -->
    <div
      v-if="item.homeBorder?.image?.src"
      slot="imgTopLeft"
      class="product-bff__border"
      :style="borderStyle(webpCheck(item.homeBorder?.image?.src))"
    ></div>
    <!-- 商品角标 -->
    <div
      v-if="item.homeBadge?.text"
      slot="imgTopLeft"
      class="product-bff__badge"
    >
      <div
        class="product-bff__badgetext"
        :style="badgeTextStyle(item.homeBadge)"
      >
        <BaseImg
          v-if="item.homeBadge?.icon?.src"
          fit="cover"
          :placeholder="{
            width: item.homeBadge?.icon?.width,
            height: item.homeBadge?.icon?.height,
          }"
          :img-src="webpCheck(item.homeBadge?.icon?.src)"
          class="not-fsp-element"
          :class="{'product-bff__badgeicon_rotate': (GB_cssRight || cssRight) && !item.homeBadge?.icon?.iconNoRotate }"
          :img-design-width="Number(item.homeBadge?.icon?.width) * 2"
          :first-screen="false"
          :style="itemIconStyle(item.homeBadge)"
        />
        <!-- 角标通常为折扣，折扣镜像时，bff已处理为 %35-，所以默认角标文案不旋转，保证 %35- 显示，特殊需旋转的可以端处理配置textRotate -->
        <span
          class="product-bff__badgetext_content"
          :class="{'product-bff__badgetext_rotate': (GB_cssRight || cssRight) && item.homeBadge?.textRotate }"
        >
          {{ item.homeBadge?.text }}
        </span>
      </div>
      <BaseImg
        v-if="item.homeBadge?.bgImage?.src"
        fit="cover"
        :placeholder="{
          width: item.homeBadge?.bgImage?.width,
          height: item.homeBadge?.bgImage?.height,
        }"
        :img-src="webpCheck(item.homeBadge?.bgImage?.src)"
        class="not-fsp-element product-bff__badgebg"
        :img-design-width="Number(item.homeBadge?.bgImage?.width) * 2"
        :first-screen="false"
      />
    </div>
    <!-- 普通的折扣角标 -->
    <div
      v-else-if="config.showNormalDiscountLabel"
      slot="imgTopLeft"
      class="product-bff__normalbadge"
    >
      <div
        v-if="Number(item?.unit_discount) > 0"
        class="product-bff__normalbadgetext"
      >
        <span
          class="product-bff__normalbadgetext_content"
          :class="{ 'product-bff__normalbadgetext_rotate': (GB_cssRight || cssRight) }"
        >
        -{{ item?.unit_discount }}<span style="display: inline-block">%</span>
        </span>
      </div>
    </div>
    <div
      v-else-if="config.showFlashSaleLabelInMainImg"
      slot="imgTopLeft"
      class="product-bff__normalbadge"
    >
      <div
        v-if="Number(item?.unit_discount) > 0"
        class="product-bff__normalbadgetext flash-sale"
      >
        <i class="suiiconfont sui_icon_flashsale_15px"></i>
        <span
          class="product-bff__normalbadgetext_content"
          :class="{ 'product-bff__normalbadgetext_rotate': (GB_cssRight || cssRight) }"
        >
          -{{ item?.unit_discount }}%
        </span>
      </div>
    </div>
    <template
      v-else
      slot="imgTopLeft"
    >
      <slot name="imgTopLeft"></slot>
    </template>
    <!-- viewmore -->
    <div
      v-if="showViewMore"
      slot="imgTopLeft"
      class="product-bff__viewmore"
    >
      <div
        class="product-bff__viewmore_content"
        @click="handleClickMore"
      >
        <span class="product-bff__viewmore_icon">
          <img
            :src="viewMoreSvg"
            alt="view more"
          />
        </span>
        <div class="product-bff__viewmore_txt">
          {{ viewMoreText }}
        </div>
      </div>
    </div>
    <!-- 商品腰带 -->
    <div
      v-if="item.homeBelt?.text"
      slot="imgBottom"
      class="product-bff__belt"
    >
      <div
        class="product-bff__belttext"
        :style="beltTextStyle(item.homeBelt)"
      >
        <div class="product-bff__belttex_relative">
          <div class="product-bff__belttex_absolute">
            <BaseImg
              v-if="item.homeBelt?.icon?.src"
              fit="cover"
              :img-src="webpCheck(item.homeBelt?.icon?.src)"
              :placeholder="{
                width: item.homeBelt?.icon?.width,
                height: item.homeBelt?.icon?.height,
              }"
              class="not-fsp-element"
              :class="{'product-bff__belticon_rotate': (GB_cssRight || cssRight) && !item.homeBelt?.icon?.iconNoRotate }"
              :img-design-width="Number(item.homeBelt?.icon?.width) * 2"
              :first-screen="false"
              :style="itemIconStyle(item.homeBelt)"
            />
            <span
              class="product-bff__belttext_content"
            >
              {{ item.homeBelt?.text }}
            </span>
          </div>
        </div>
      </div>
      <BaseImg
        v-if="item.homeBelt?.bgImage?.src"
        fit="contain"
        :img-src="webpCheck(item.homeBelt?.bgImage?.src)"
        :placeholder="{
          width: item.homeBelt?.bgImage?.width,
          height: item.homeBelt?.bgImage?.height,
        }"
        class="not-fsp-element product-bff__beltbg"
        :class="{'product-bff__beltbg_rotate': (GB_cssRight || cssRight) && !item.homeBelt?.bgImage?.bgNoRotate }"
        :img-design-width="Number(item.homeBelt?.bgImage?.width) * 2"
        :first-screen="false"
      />
    </div>
    <template
      v-else
      slot="imgBottom"
    >
      <slot name="imgBottom"></slot>
    </template>
    <template
      slot="price"
    >
      <HomeBffPrice
        v-if="!hiddenPrice"
        :goods-info="item"
        :config="config"
        :suggested-sale-type="suggestedSaleType"
        :price-font-size="priceFontSize"
      />
    </template>
  </SimpleProductItem>
</template>

<script>
import SimpleProductItem from 'public/src/pages/components/product/item_v3/simpleCard.vue'
import HomeBffPrice from './HomeBffPrice.vue'
import { transfromAndCutImg } from 'public/src/services/resource'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'

export default {
  name: 'ProductItemSimpleCcc',
  components: {
    SimpleProductItem,
    BaseImg,
    HomeBffPrice
  },
  inheritAttrs: false,
  props: {
    lcp: {
      type: Boolean,
      default: false
    },
    showViewMore: {
      type: Boolean,
      default: false
    },
    viewMoreText: {
      type: String,
      default: ''
    },
    context: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default: () => {}
    },
    showGoodsBottom: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => {}
    },
    column: {
      type: [String, Number],
      default: ''
    },
    constantData: {
      type: Object,
      default: () => {}
    },
    goodsItemInfo: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    brand: {
      type: String,
      default: 'shein'
    },
    cssRight: { // GB_cssRight存在不准确的情况，所以传入cssRight兼容
      type: Boolean,
      default: false
    },
    // 来源组件
    compSrc: {
      type: String,
      default: ''
    },
    cropRate: {
      type: String,
      default: '3-4'
    },
    suggestedSaleType: {
      type: String,
      default: ''
    },
    // bff返回驼峰字体大小
    priceFontSize: {
      type: Object,
      default: () => {}
    },
    // 用于控制首页组件的裁切
    compImgDesignWidth: {
      required: true,
      type: Number,
      default: 192
    },
    hiddenPrice: {
      type: Boolean || Number,
      default: false
    }
  },
  data() {
    const { GB_cssRight = false } = this.context || {}
    return {
      GB_cssRight
    }
  },
  computed: {
    viewMoreSvg({ GB_cssRight }) {
      return GB_cssRight || this.cssRight ? `https://shein.ltwebstatic.com/svgicons/2024/07/11/17206852561468256043.svg` : `https://shein.ltwebstatic.com/svgicons/2024/07/11/17206852562878525324.svg`
    },
  },
  mounted() {
    this.$refs[`product_item_simple_${this.index}`]?.setElAttr() // 设置埋点属性在dom上
  },
  methods: {
    handleClickMore() {
      this.$emit('viewMore')
    },
    clickItem(paylaod) {
      this.$emit('clickItem', paylaod)
    },
    openQuickAdd(paylaod) {
      this.$emit('openQuickAdd', paylaod)
    },
    cutImg(imgUrl, forceTransform = false) {
      const {
        deviceData = '',
        isSupportWeb = '',
        sceneMap = {},
        isSupprotCut = ''
      } = this.context?.RESOURCE_SDK || {}
      const cutData = {
        deviceData,
        isSupportWebp: forceTransform ? true : Boolean(isSupportWeb),
        isSupprotCut: forceTransform ? true : Boolean(isSupprotCut),
        imgUrl,
        designWidth: 950,
        sceneMap,
        exp: 'unCut=0#max=950'
      }
      return transfromAndCutImg(cutData)
    },
    checkSizeUnit(size) {
      return /(px|em|rem|vh|vw|vmin|vmax|%)$/.test(size)
    },
    badgeTextStyle(badge) {
      let fontSize = '0.24rem'
      if (badge.textSize && !this.checkSizeUnit(badge.textSize)) {
        fontSize = (badge.textSize / 37.5) + 'rem'
      }
      return {
        fontSize,
        fontWeight: badge.isInBold ? '700' : '400',
        color: badge.textColor || '#000'
      }
    },
    itemIconStyle(iconObj) {
      let width = '0.24rem'
      let height = '0.24rem'
      if (iconObj.icon?.width) {
        if (iconObj.icon?.width && !this.checkSizeUnit(iconObj.icon?.width)) {
          width = (iconObj.icon.width / 37.5 / 3) + 'rem' // 缩小三倍
        }
      } else {
        if (iconObj.textSize && !this.checkSizeUnit(iconObj.textSize)) {
          width = (iconObj.textSize / 37.5 / 3) + 'rem' // 缩小三倍
        }
      }
      if (iconObj.icon?.height) {
        if (iconObj.icon?.height && !this.checkSizeUnit(iconObj.icon?.height)) {
          height = (iconObj.icon.height / 37.5 / 3) + 'rem' // 缩小三倍
        }
      } else {
        if (iconObj.textSize && !this.checkSizeUnit(iconObj.textSize)) {
          height = (iconObj.textSize / 37.5 / 3) + 'rem' // 缩小三倍
        }
      }
      return {
        width,
        height
      }
    },
    borderStyle(src) {
      return {
        backgroundImage: `url(${this.cutImg(src, true)})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat'
      }
    },
    beltTextStyle(belt) {
      let fontSize = '0.24rem'
      if (belt.textSize && !this.checkSizeUnit(belt.textSize)) {
        fontSize = (belt.textSize / 37.5) + 'rem'
      }
      return {
        fontSize,
        fontWeight: belt.textWeight || '400',
        height: belt.textHeight || '100%',
        color: belt.textColor || '#000'
      }
    },
    webpCheck(src) {
      return src?.replace('.webp', '.png')
    }
  }
}
</script>

<style lang="less">
.simple-row-item.product-bff {
  width: 100%;
  background-color: transparent;// 背景透明，避免白色造成四角未到圆角
  .product-card__bottom-wrapper {
    padding: 0 0 2px;
  }
  .product-card__top-wrapper {
    border-radius: 0.055rem;
  }
  .crop-image-container .product-card__main-img {
    border-radius: 0.055rem;
  }
  .top-wrapper__top-left {
    width: 100%;
  }
  .content__sale-price-wrapper, .prices-info__sale-price {
    max-width: 100%;
  }
  .prices-info__sale-price {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
}
.product-bff {
  overflow: hidden;
  &__badge {
    min-width: 0.75rem;
    max-width: calc(100% - 4px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    overflow: hidden;
  }
  &__badge, &__badge .base-img__inner {
    border-bottom-right-radius: 0.055rem;
  }
  &__badgetext {
    text-align: center;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  &__badgetext_content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 1;
    // 镜像方向保持一致，避免 %23- 展示为 -%23
    direction: ltr /* rtl: ltr */;
  }
  &__badgetext_rotate {
    direction: ltr;
  }
  // 普通折扣角标
  &__normalbadge {
    min-width: 0.75rem;
    max-width: calc(100% - 4px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    overflow: hidden;
  }
  &__normalbadge, &__normalbadge .base-img__inner {
    border-bottom-right-radius: 0.055rem;
  }
  &__normalbadgetext {
    font-size: 0.24rem;
    text-align: center;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
    color: #fff;
    background-color: rgb(250, 99, 56);
    &.flash-sale {
      background: #facf19;
      color: #222;
      flex-direction: column;
      i {
        display: inline-block;
        height: 15px;
      }
    }
  }
  &__normalbadgetext_content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 1;
    // 镜像方向保持一致，避免 %23- 展示为 -%23
    direction: ltr /* rtl: ltr */;
  }
  &__normalbadgetext_rotate {
    direction: ltr;
  }
  &__badgeicon_rotate {
    transform: scaleX(-1);
  }
  &__badgebg.base-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
  &__viewmore {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: @zindex-hack + 1;
  }
  &__viewmore_content {
    display: list-item;
    list-style: none;
    text-align: center;
    width: 100%;
  }
  &__viewmore_icon {
    display: inline-block;
    margin-bottom: 0.2133rem;
  }
  &__viewmore_txt {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  &__border {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 0.055rem;
  }
  &__belt {
    width: 100%;
    overflow: hidden;
    z-index: 2;
  }
  &__belt, &__belt .base-img__inner {
    border-bottom-left-radius: 0.055rem;
    border-bottom-right-radius: 0.055rem;
  }
  &__beltbg {
    position: relative;
    z-index: 1;
  }
  &__beltbg_rotate {
    transform: scaleX(-1);
  }
  &__belttext {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
    z-index: 2;
  }
  &__belttex_relative {
    // 控制腰带高度
    position: relative;
    width: 100%;
    height: 100%;
  }
  &__belttex_absolute {
    // 控制腰带居中
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 2px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  &__belticon_rotate {
    transform: scaleX(-1);
  }
  &__belttext_content {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__view_more {
    .product-card__bottom_wrapper {
      opacity: 0.4;
    }
  }
  &__view_more,
  &__border_out {
    .top-wrapper__top-left {
      height: 100%;
    }
  }
}
</style>
